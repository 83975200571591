import React from "react"
import { Link, graphql } from "gatsby"

import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Tietoa kohteesta" />
      <section className="section is-relative is-paddingless page-image">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="cover">
        </Img>
      </section>
      <section className="section page-title has-background-light">
        <div className="container">
          <h1 className="title is-5 is-uppercase">tietoa kohteesta</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content is-medium">
            <h1 className="title is-5">Kauppapuisto</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sapien enim, fringilla quis placerat nec, ultricies non massa. 
              Proin vel velit ullamcorper, dapibus eros eu, laoreet erat. Pellentesque cursus eros augue, sed sagittis nibh suscipit ac. Quisque in erat ipsum. 
              Integer tincidunt enim non nibh bibendum porta. Nullam velit justo, placerat eu dui a, mollis aliquet mi. Etiam vitae tincidunt nisl. 
              Curabitur eleifend dictum lorem. Donec posuere nulla eu lectus cursus vulputate. Suspendisse potenti. Morbi aliquam facilisis mattis.
          </p>
          <h1 className="title is-5">Väliotsikko</h1>
          <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sapien enim, fringilla quis placerat nec, ultricies non massa. 
              Proin vel velit ullamcorper, dapibus eros eu, laoreet erat. Pellentesque cursus eros augue, sed sagittis nibh suscipit ac. Quisque in erat ipsum. 
              Integer tincidunt enim non nibh bibendum porta. Nullam velit justo, placerat eu dui a, mollis aliquet mi. Etiam vitae tincidunt nisl. 
              Curabitur eleifend dictum lorem. Donec posuere nulla eu lectus cursus vulputate. Suspendisse potenti. Morbi aliquam facilisis mattis.
          </p>
          <h1 className="title is-5">Väliotsikko</h1>
          <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sapien enim, fringilla quis placerat nec, ultricies non massa. 
              Proin vel velit ullamcorper, dapibus eros eu, laoreet erat. Pellentesque cursus eros augue, sed sagittis nibh suscipit ac. Quisque in erat ipsum. 
              Integer tincidunt enim non nibh bibendum porta. Nullam velit justo, placerat eu dui a, mollis aliquet mi. Etiam vitae tincidunt nisl. 
              Curabitur eleifend dictum lorem. Donec posuere nulla eu lectus cursus vulputate. Suspendisse potenti. Morbi aliquam facilisis mattis.
          </p>
          <h1 className="title is-5">Väliotsikko</h1>
          <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sapien enim, fringilla quis placerat nec, ultricies non massa. 
              Proin vel velit ullamcorper, dapibus eros eu, laoreet erat. Pellentesque cursus eros augue, sed sagittis nibh suscipit ac. Quisque in erat ipsum. 
              Integer tincidunt enim non nibh bibendum porta. Nullam velit justo, placerat eu dui a, mollis aliquet mi. Etiam vitae tincidunt nisl. 
              Curabitur eleifend dictum lorem. Donec posuere nulla eu lectus cursus vulputate. Suspendisse potenti. Morbi aliquam facilisis mattis.
          </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="cta-buttons buttons flex-div">
          <Link className="button is-primary is-medium" role="button" to="/">Etusivu</Link>
            <Link className="button is-primary is-medium" role="button" to="/yhteystiedot">Yhteystiedot</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
query InfoPageQuery {
  file(relativePath: {eq: "02.jpg"}) {
    childImageSharp {
      fluid(quality: 80, maxWidth: 1300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
export default InfoPage
